import { render, staticRenderFns } from "./PenaltyCardHeader.vue?vue&type=template&id=e32475b0&scoped=true&lang=pug&"
import script from "./PenaltyCardHeader.vue?vue&type=script&lang=ts&"
export * from "./PenaltyCardHeader.vue?vue&type=script&lang=ts&"
import style0 from "./PenaltyCardHeader.vue?vue&type=style&index=0&id=e32475b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e32475b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VChip,VIcon,VTooltip})
