





































// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES
import { CountResource, IPenaltySumToPay, PenaltyStatusEnum } from '@/store/types'

//  HELPERS
import { formatCost } from '@/utils/functions'

@Component
export default class Statistics extends Vue {
  @Prop({ default: () => ({}) })
  private penaltiesSumToPay!: IPenaltySumToPay

  @Prop({ default: () => ({}) })
  private items!: CountResource[]

  private getColor (value: string) {
    switch (value) {
    case PenaltyStatusEnum.NEW : return 'red-base'
    case PenaltyStatusEnum.CHALLENGING : return 'red-darken-1'
    case PenaltyStatusEnum.NOT_PAID : return 'red-darken-2'
    case PenaltyStatusEnum.PAID : return 'red-darken-3'
    default: break
    }
  }

  private formatCost = formatCost
}
