




















































































































// CORE
import { omit } from 'lodash'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// COMPONENTS
import Statistics from '@/components/Statistics.vue'
import TableFooter from '@/components/TableFooter.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import PenaltyCardContent from '@/components/cards/penalty/PenaltyCardContent.vue'
import PenaltyCardHeader from '@/components/cards/penalty/PenaltyCardHeader.vue'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'

// INTERFACES
import { CountResource, NameValueResource, PaginationMeta, PenaltyCarParkIdGetParams, PenaltyResource } from '@/store/types'

// STORE
import DriverPenaltyModule from '@/store/modules/driver/driver-penalty'
import FleetPenaltyModule from '@/store/modules/fleet/penalty'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'
import CommonModule from '@/store/modules/common'

// HELPERS
// import { openNewTab } from '@/utils/functions'

@Component({
  components: {
    PenaltyCardHeader,
    PenaltyCardContent,
    CaptionCard,
    DefaultLayout,
    Statistics,
    TableFooter,
  },
})
export default class Penalty extends Mixins(SystemMixin, NotifyMixin) {
  private isVisibleVin = false
  private isReady = false
  private isNoData = false
  private openPanel = ''

  fetchPenaltiesIntervalId!: any

  private checkeds: number[] = []

  readonly excelDownloadDescription = 'Будут выгружены штрафы, указанные на странице. Если были выбраны фильтры или определенные штрафы, то выгрузка сформируется по ним.'
  readonly pdfDownloadDescription = 'Будут выгружены файлы из всех штрафов. Если были выбраны фильтры или определенные штрафы, то выгрузка сформируется по ним.'

  private get isEmpty (): boolean {
    return !this.penalties.length
  }

  private get checkedItems (): PenaltyResource[] {
    return this.penalties.filter(item => this.checkeds.includes(item.id))
  }

  private get warnCounter () {
    if(this.isFilteredPenalties){
      return this.penalties.filter(item => !item.fileLink || item.fileLink.includes('.jpg')).length
    }
    return this.checkedItems.filter(item => !item.fileLink || item.fileLink.includes('.jpg')).length
  }

  private get isPaymentBtnDisabled (): boolean {
    if (this.checkedItems && this.checkedItems.length) {
      const vin = this.checkedItems[0].vin
      const items = this.checkedItems.filter(item => {
        return (
          item.vin === vin && item.paid
        )
      }).length

      return items !== this.checkedItems.length
    } else {
      return true
    }
  }

  private get isPDFDownloadBtnDisable () {
    return ((this.isFilteredPenalties && this.penalties.length <= this.warnCounter) || !this.isFilteredPenalties) && (this.checkedItems.length <= 0 || this.checkedItems.length <= this.warnCounter)
  }

  private get isFilteredPenalties () {
    return Object.keys(omit(this.filter, ['page', 'perPage', ''])).length !== 0
  }

  private get penaltiesSumToPay (): any {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.fleetPenaltiesSumToPay
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.fleetPenaltiesSumToPay
    } else {
      return DriverPenaltyModule.penaltiesSumToPay
    }
  }

  private get penalties (): PenaltyResource[] {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.fleetPenalties.data
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.fleetPenalties.data
    } else {
      return DriverPenaltyModule.penalties.data
    }
  }

  private get dictionary (): CountResource[] {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.statusesDictionary
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.statusesDictionary
    } else {
      return DriverPenaltyModule.statusesDictionary
    }
  }

  private get dictionaryWithoutChallenging () {
    return this.dictionary.filter(item => item.value !== 'challenging')
  }

  private get indeterminate (): boolean {
    const vixibleCounter = this.checkedItems.length
    return this.penalties.length !== vixibleCounter && vixibleCounter !== 0
  }

  private get penaltiesSum (): NameValueResource[] {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.fleetPenaltiesSum
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.fleetPenaltiesSum
    } else {
      return DriverPenaltyModule.penaltiesSum
    }
  }

  private get filter (): PenaltyCarParkIdGetParams {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.penaltyFilter
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.penaltyFilter
    } else {
      return DriverPenaltyModule.penaltyFilter
    }
  }

  private set filter (value: PenaltyCarParkIdGetParams) {
    FleetPenaltyModule.setFilters(value)
    this.fetchPenalties()
    this.openPanel = ''
  }

  private get paginator (): PaginationMeta {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.fleetPenalties.meta
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.fleetPenalties.meta
    } else {
      return DriverPenaltyModule.penalties.meta
    }
  }

  private get hasPDFFile (): boolean {
    return FleetPenaltyModule.fleetPenaltiesHasFile
  }

  private get all (): boolean {
    const vixibleCounter = this.checkedItems.length

    return this.penalties.length === vixibleCounter && this.penalties.length !== 0
  }

  private set all (value: boolean) {
    this.checkeds = []
    if (value) {
      this.penalties.forEach(item => {
        this.checkeds.push(item.id)
      })
    }
  }

  private get activeStatus (): string | undefined {
    if (this.role === 'fleet') {
      return FleetPenaltyModule.penaltyFilter.status ?? 'all'
    } else if (this.role === 'mechanic') {
      return FleetPenaltyModule.penaltyFilter.status ?? 'all'
    } else {
      return DriverPenaltyModule.penaltyFilter.status ?? 'all'
    }
  }

  private set activeStatus (value: string | undefined) {
    if (this.role === 'fleet') {
      FleetPenaltyModule.setPage(1)
      FleetPenaltyModule.setStatus(value ? value : undefined)
      this.fetchPenalties()
    } else if (this.role === 'mechanic') {
      FleetPenaltyModule.setPage(1)
      FleetPenaltyModule.setStatus(value ? value : undefined)
      this.fetchPenalties()
    } else {
      DriverPenaltyModule.setPage(1)
      DriverPenaltyModule.setStatus(value)
      this.fetchPenalties()
    }
  }

  private get warnList () {
    return this.checkedItems.length
      ? this.checkedItems.filter(({ fileLink }) => !fileLink).map(({ protocolNumber }) => protocolNumber)
      : this.penalties.filter(({ fileLink }) => !fileLink).map(({ protocolNumber }) => protocolNumber)
  }

  private mounted () {
    this.fetchPenalties(true)
    this.$bus.$on('penaltiesNeedUpdate', this.fetchPenalties.bind(this))

    if (this.role === 'fleet') {
      FleetPenaltyModule.resetFilters()
      FleetPenaltyModule.getFleetPenaltiesSum(+this.entity)
      FleetPenaltyModule.getStatusesDictionary(+this.entity)
    } else if (this.role === 'mechanic') {
      FleetPenaltyModule.resetFilters()
      FleetPenaltyModule.getFleetPenaltiesSum(+this.entity)
      FleetPenaltyModule.getStatusesDictionary(+this.entity)
    } else {
      DriverPenaltyModule.resetFilters()
      DriverPenaltyModule.getPenaltiesSum(+this.entity)
      DriverPenaltyModule.getStatusesDictionary(+this.entity)
    }

    this.fetchPenaltiesIntervalId = setInterval(this.fetchPenalties.bind(this), 15000)
  }

  // private changePerPage (value: number) {
  //   FleetPenaltyModule.setPerPage(value)
  // }

  private changePage (value: number) {
    FleetPenaltyModule.setPage(value)
    this.fetchPenalties()
  }

  private fetchPenalties (first = false) {
    if (this.role === 'fleet') {
      FleetPenaltyModule.getFleetPenalties(+this.entity)
        .then(() => {
          this.isReady = true

          if (first) {
            this.isNoData = !this.penalties.length
          }
        })
        .catch((error) => {
          this.notifyError(error)
        })
    } else if (this.role === 'driver') {
      DriverPenaltyModule.getPenalties(+this.entity)
        .then(() => {
          this.isReady = true

          if (first) {
            this.isNoData = !this.penalties.length
          }
        })
        .catch((error) => {
          this.notifyError(error)
        })
    } else if (this.role === 'mechanic') {
      FleetPenaltyModule.getFleetPenalties(+this.entity)
        .then(() => {
          this.isReady = true

          if (first) {
            this.isNoData = !this.penalties.length
          }
        })
        .catch((error) => {
          this.notifyError(error)
        })
    }
  }

  private paymentPenalties () {
    if (!this.isPaymentBtnDisabled) {
      const payload = { params: { penaltyIds: this.checkedItems.map(item => item.id) }, user: { role: this.role, id: this.entity } }
      CommonModule.paymentPenalties(payload)
        .then((link) => {
          const tab = window.open(link, '_blank')
          if (tab) {
            tab.focus()
          }
        })
    }
  }

  private downloadMultiplePenalties () {
    FleetPenaltyModule.getPenaltiesPDFLink({
      ids: this.checkedItems.filter(item => !!item.fileLink).map(item => item.id),
      ...omit(this.filter, ['page', 'perPage']),
    })
      .then(({ errors, link }) => {
        if (!errors?.length && link) {
          const tab = window.open(link, '_blank')

          if (tab) {
            tab.focus()
          }
        } else {
          this.$openModal(
            'PDFDownloadModal',
            'small-no-absolute',
            {
              exportURL: link,
              checkedItems: this.checkedItems.length > 0 ? this.checkedItems : this.penalties,
              errorItems: errors,
            },
          )
        }
      })
  }

  private beforeDestroy () {
    clearInterval(this.fetchPenaltiesIntervalId)
    FleetPenaltyModule.resetFilters()
    DriverPenaltyModule.resetFilters()
  }

  private downloadExcel () {
    this.$openModal(
      'PenaltyExcelDownloadModal',
      'small',
      {
        exportURL: '/penalty/export/',
        // exportURL: `/penalty${this.role === 'fleet' ? '' : '-driver'}/export/`,
        filters: omit(this.filter, ['page', 'perPage']),
        ids: this.checkeds,
      },
    )
  }

  @Watch('warnCounter')
  private warnCounterWatcher () {
    const alias = 'penaltyWarnCounter'

    if (this.warnCounter > 0) {
      this.notifyWarn(`Выбраны штрафы (${this.warnCounter}) без возможности выгрузки в PDF из-за отсутствия документов или неверного формата файла`, alias)
    } else {
      this.removeNotifyByAlias(alias)
    }
  }
}
