var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{staticClass:"penalty",attrs:{"heading":"Штрафы","filters":_vm.dictionaryWithoutChallenging,"form-filters":_vm.filter,"drawerComponentName":"PenaltyFilterForm","activeStatus":_vm.activeStatus,"isHideFiltersPanel":_vm.isNoData},on:{"update:activeStatus":function($event){_vm.activeStatus=$event},"update:active-status":function($event){_vm.activeStatus=$event}},scopedSlots:_vm._u([{key:"before-panel",fn:function(){return [_c('div',{staticClass:"penalty__statistics-row sp-sm"},[(!_vm.isNoData)?_c('Statistics',{attrs:{"penaltiesSumToPay":_vm.penaltiesSumToPay,"items":_vm.penaltiesSum}}):_vm._e()],1)]},proxy:true},{key:"panel-actions",fn:function(){return [_c('VBtn',{staticClass:"sp-r-xxs",class:[{ 'btn--square': _vm.$vuetify.breakpoint.xsOnly },'btn--secondary'],on:{"click":_vm.downloadExcel}},[_vm._v(_vm._s(!_vm.$vuetify.breakpoint.xsOnly ? 'Excel' : '')),_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1),_c('VTooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({staticClass:"penalty-card-header__tooltip-icon",attrs:{"color":"dark-blue","center":""}},'VIcon',attrs,false),on),[_vm._v("mdi-help-box")])]}}])},[_vm._v(_vm._s(_vm.excelDownloadDescription))])]},proxy:true}])},[(!_vm.isEmpty && !_vm.isNoData)?_c('div',{staticClass:"penalty__table-control-row control-row"},[_c('div',{staticClass:"control-row__checkbox-container"},[_c('VCheckbox',{staticClass:"penalty__table-check-all",attrs:{"color":"error","dense":"","hide-details":"","indeterminate":_vm.indeterminate},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Paragraph',{attrs:{"size":"subtitle","weight":"regular"}},[_vm._v("Вся страница")])]},proxy:true}],null,false,1551178591),model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}}),_c('Link',{attrs:{"url":"#","mod":"dark-blue","icon":"$alphaVBox","tag":"div"},on:{"click":function($event){_vm.isVisibleVin = !_vm.isVisibleVin}}},[_vm._v(_vm._s(_vm.isVisibleVin ? 'Скрыть VIN' : 'Показать VIN'))])],1),_c('div',{staticClass:"control-row__button-container"},[_c('VTooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"sp-r-xxs"},'div',attrs,false),_vm.isPDFDownloadBtnDisable ? on : null),[_c('VBtn',{staticClass:"btn--secondary",attrs:{"disabled":_vm.isPDFDownloadBtnDisable},on:{"click":function($event){return _vm.downloadMultiplePenalties()}}},[_vm._v("PDF"),_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1)],1)]}}],null,false,371213129)},[_vm._v("Выгрузка в PDF станет доступна при выборе штрафов с файлом постановления. Выбранные фильтры влияют на формирование выгрузки.")]),_c('VTooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({staticClass:"penalty-card-header__tooltip-icon",attrs:{"color":"dark-blue","center":""}},'VIcon',attrs,false),on),[_vm._v("mdi-help-box")])]}}],null,false,4140812145)},[_vm._v(_vm._s(_vm.pdfDownloadDescription))])],1)]):_vm._e(),_c('VExpansionPanels',{staticClass:"expansion-table penalty__table",model:{value:(_vm.openPanel),callback:function ($$v) {_vm.openPanel=$$v},expression:"openPanel"}},_vm._l((_vm.penalties),function(item,index){return _c('VExpansionPanel',{key:item.id},[_c('VExpansionPanelHeader',[_c('PenaltyCardHeader',{attrs:{"item":item,"isVisibleVin":_vm.isVisibleVin,"checkeds":_vm.checkeds},on:{"update:item":function($event){item=$event},"update:checkeds":function($event){_vm.checkeds=$event}}})],1),_c('VExpansionPanelContent',[_c('PenaltyCardContent',{attrs:{"item":item}})],1)],1)}),1),(_vm.isReady && _vm.isEmpty && !_vm.isNoData)?_c('CaptionCard',{staticClass:"empty-table sp-sm",attrs:{"heading":"Нет данных","color":"transparent","padding-size":"sm"}},[_c('Paragraph',{attrs:{"size":"subtitle"}},[_vm._v("Попробуйте перезагрузить страницу или изменить параметры поиска.")])],1):_vm._e(),(_vm.isNoData)?_c('CaptionCard',{staticClass:"empty-table sp-sm",attrs:{"color":"transparent","heading":"У вас нет штрафов","padding-size":"sm"}},[_c('Paragraph',{attrs:{"size":"subtitle"}},[_vm._v("Вы не получили ни одного штрафа.")])],1):_vm._e(),(_vm.penalties.length)?_c('TableFooter',{attrs:{"pagination":_vm.paginator,"filter":_vm.filter,"mod":"paginator","sticky":""},on:{"update:filter":function($event){_vm.filter=$event},"pagination-input":function($event){_vm.all = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }